import React from "react"
import {MSG1} from "../../const"

import Container from "../container"
import "./deltag.scss"

interface DeltagProps {
  invert?: boolean
}

const Deltag = ({invert}: DeltagProps) => {
  return (
    <div className={invert ? "deltag deltag--invert" : "deltag"}>
      <Container>
        <div className="deltag__inner">
          <h2>Oplev et foredrag</h2>
          <p>Du kan bestille billet til Niels Jensens foredrag via forskellige foreninger:</p>
          <ul>
            <li><a className="normal anim" href="https://aof.dk/sogeresultater/?page=1&query=Niels%20Jensen&showAll=true" rel="noopener noreferrer" target="_blank">AOF Sydvestsjælland, Slagelse og Ringsted</a></li>
            <li><a className="normal anim" href="https://aof.dk/sogeresultater/?departments=6630&page=1&query=niels%20jensen&showAll=true" rel="noopener noreferrer" target="_blank">AOF Susålandet Næstved</a></li>
            <li><a className="normal anim" href="https://www.fof.dk/da/kogebugt/soegeresultat#?cludoquery=niels%20jensen&cludopage=1&cludorefurl=https%3A%2F%2Fwww.fof.dk%2Fda%2Fkogebugt&cludorefpt=FOF%20K%C3%B8ge%20Bugt&cludoinputtype=standard" rel="noopener noreferrer" target="_blank">FOF Køge-Greve</a></li>
            <li><a className="normal anim" href="https://www.fof.dk/da/kbh-omegn/soegeresultat#?cludoquery=niels%20jensen&cludopage=1&cludorefurl=https%3A%2F%2Fwww.fof.dk%2Fda%2Fkbh-omegn%2Fforedrag%2Fforedrag&cludorefpt=Foredrag&cludoinputtype=standard" rel="noopener noreferrer" target="_blank">FOF Københavns Omegn</a></li>
            <li><a className="normal anim" href="https://grevemuseum.dk/" rel="noopener noreferrer" target="_blank">Greve Museum</a></li>
            <li><a className="normal anim" href="https://hemingwayclub.dk/klub/solroed-2/" rel="noopener noreferrer" target="_blank">Hemingway Club2 Solrød</a></li>
            <li><a className="normal anim" href="https://hemingwayclub.dk/klub/hilleroed-1/" rel="noopener noreferrer" target="_blank">Hemingway Club Hillerød</a></li>
            <li><a className="normal anim" href="https://solrodkirker.dk/" rel="noopener noreferrer" target="_blank">Solrød Strandkirke</a></li>
            <li><a className="normal anim" href="https://www.sorgenfrikirke.dk/aktiviteter/foredrag" rel="noopener noreferrer" target="_blank">Sorgenfri Kirke</a></li>
            <li><a className="normal anim" href="https://www.christianskirken-lyngby.dk/" rel="noopener noreferrer" target="_blank">Christianskirken i Lyngby</a></li>
            <li><a className="normal anim" href="https://www.aeldresagen.dk/lokalafdelinger/gladsaxe" rel="noopener noreferrer" target="_blank">Ældresagen, Gladsaxe</a></li>
          </ul>
          <p>For nærmere oplysninger kontakt venligst Niels på telefon <a className="normal anim" href="tel:40555588">40555588</a> eller send en mail på <a className="normal anim" href={`mailto:niels.jensen@jetra.dk?subject=${MSG1}`}>niels.jensen@jetra.dk</a>.</p>
        </div>
      </Container>
    </div>
  )
}
export default Deltag;